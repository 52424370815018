import axios from '../uitls/require'

export default {
  messageList(data){
    return axios.post('/admin/message/list',data)
  },
  create(data){
    return axios.post('/admin/message/create',data)
  },
  getMessageInfo(id){
    return axios.get('/admin/message/info?id=' + id)
  },
  messageUpdate(data){
    return axios.post('/admin/message/update',data)
  },
  messageDelete(data){
    return axios.post('/admin/message/delete',data)
  },
  messageSend(data){
    return axios.post('/admin/message/send',data)
  },
  messageCancel(data) {
    return axios.post('/admin/message/cancel',data)
  },
  messageUpOrSend(data) {
    return axios.post('/admin/message/update-and-send',data)
  },
  messageAddOrSend(data) {
    return axios.post('/admin/message/create-and-send',data)
  },
//   upLoad(data) {
//     return axios.post('/image/upload', data);
//   },
}
