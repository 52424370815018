export const langData = [{
    type: 'cn',
    text: '中文',
    lang: '中文',
    prop: {
        titleProp: 'title',
        contentProp: 'content',
        descProp: 'description'
    }
}, {
    type: 'en',
    text: '英文',
    lang: 'En',
    prop: {
        titleProp: 'title_en',
        contentProp: 'content_en',
        descProp: 'description_en'
    }
}];
