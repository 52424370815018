<template>
    <!-- 保存且推送确认弹框 -->
    <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible="dialogSend"
        :before-close="handleCancel"
        width="458px"
        class="aops-send-dialog pop-dialog sure-dialog"
    >
    <div class="sure-dialog-content">
        <span class="aop-sure-tip">您将保存并直接推送该消息</span>
    </div>
    <div slot="footer" class="dialog-footer">
        <el-button
            v-loading="confirmLoading"
            class="confirm-button"
            @click="handleSubmit()"
        >确认</el-button>
        <el-button class="cancel-button" @click="handleCancel()">取消</el-button>
    </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        dialogSend: {
            type: Boolean,
            default: false
        },
        confirmLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        /**
         * 点击确认按钮
        */
        handleSubmit() {
            this.$emit('onSendSubmit');
        },
        /**
         * 点击取消按钮，关闭确认框
        */
        handleCancel() {
            this.$emit('update:dialogSend', false);
        }
    }
};
</script>
<style lang="less" scoped>
.aops-send-dialog {
    .aop-sure-tip {
        color: #000;
        font-weight: 500;
        font-size: 18px;
        font-style: normal;
        line-height: 27px;
    }
}
</style>
