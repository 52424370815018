<template>
    <div class="aops-notice-edit-title">
        <div class="aop-edit-back" @click="handleBack">
            <i class="iconfont">&#xe647;</i>
            <span class="aop-edit-back-text">返回</span>
        </div>
        <div class="aop-edit-title">{{ !id ? "新增" : "编辑" }}推送</div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            default: ''
        }
    },
    methods: {
        /**
         * 返回列表页
        */
        handleBack() {
            this.$emit('handleBack');
        }
    }
};
</script>
<style lang="less">
@import "./title.less";
</style>
