// 图文编辑器配置
export const textImgOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ align: [] }],
    ['link', 'image', 'video']
];
// 文字编辑器配置
export const textOptions = [];

export const titlePlaceHolder = {
    cn: '请输入名称',
    en: 'Please enter a title'
};
// 中文/英文最多可输入的简介长度
export const textareaMaxLen = {
    cn: 150,
    en: 300
};
// 中文/英文简介
export const descPlaceholder = {
    cn: `请输入内容${textareaMaxLen.cn}个字符以内...`,
    en: `Please enter content within ${textareaMaxLen.en} characters...`
};
// 中文/英文最多可输入的长度
export const maxSize = {
    cn: 140,
    en: 280
};
// 文本内容--中文/英文placeholder
export const placeholder = {
    cn: `请输入内容${maxSize.cn}个字符以内...`,
    en: `Please enter content within ${maxSize.en} characters...`
};
// 图文内容--中文/英文placeholder
export const contPlaceholder = {
    cn: '请输入内容..',
    en: 'Please enter content..'
};
