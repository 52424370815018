<template>
    <div class="aops-customers-table">
        <div class="aop-customers-title">筛选</div>
        <el-form
            :inline="true"
            :model="customerForm"
            label-width="72px"
        >
            <el-form-item label="用户名称">
                <el-input
                    v-model="customerForm.username"
                    placeholder="输入用户名称"
                    clearable
                />
            </el-form-item>
            <el-form-item label="用户邮箱">
                <el-input
                    v-model="customerForm.email"
                    placeholder="输入邮箱"
                    clearable
                />
            </el-form-item>
            <el-form-item>
                <span
                    class="aop-form-btn"
                    @click="handleSearchCus()"
                >查询</span>
                <span
                    class="aop-clear-btn aop-form-btn"
                    @click="cancelCus()"
                >清空</span>
            </el-form-item>
        </el-form>
        <el-table
            ref="tableData"
            v-loading="tableLoading"
            header-cell-class-name="table-head aop-table-head"
            cell-class-name="table-cell"
            row-class-name="aop-table-row"
            :data="customerData"
            row-key="id"
            height="515px"
        >
            <el-table-column
                v-for="col in tableColumns"
                :key="col.prop"
                :prop="col.prop"
                :label="col.label"
                :width="col.width"
                :min-width="col.minWidth"
            >
                <template v-if="col.prop === 'add'" slot="header">
                    <el-button
                        type="text"
                        class="aop-table-header-oper"
                        @click="handleAdd('all')"
                    >全部添加</el-button>
                </template>
                <template slot-scope="{ row }">
                    <div v-if="col.prop === 'add'">
                        <i
                            v-if="!row.isAdd"
                            class="iconfont cursor-pointer"
                            @click="handleAdd(row)"
                        >&#xe668;</i>
                        <span v-if="row.isAdd">已添加</span>
                    </div>
                    <div v-else>{{ row[col.prop] }}</div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            v-if="customerData.length !== 0"
            :current-page.sync="pageNumber"
            :page-sizes="[10, 20, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        ></el-pagination>
    </div>
</template>

<script>
import cusApi from '@/api/customer';
import { tableColumns } from './data';

export default {
    props: {
        // 表格loading
        tableLoading: { type: Boolean, default: false },
        // 表格数据
        customerData: {
            type: Array,
            default: () => ([])
        },
        // 当前已选用户集合
        addCusData: {
            type: Array,
            default: () => ([])
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            customerForm: { // 查询form
                username: '',
                email: ''
            },
            tableColumns, // 表格的列
            // 分页数据
            pageSize: 10,
            pageNumber: 1,
            total: 0
        };
    },
    watch: {
        /**
         * 监听表格loading状态
         * @param {Boolean} newVal 当前loading状态
        */
        tableLoading(newVal) {
            if (newVal) {
                this.searchCus();
            }
        }
    },
    methods: {
        /**
         * 获取用户数据
        */
        searchCus() {
            const params = {
                limit: this.pagesize,
                page: this.pageNumber,
                filter: this.customerForm
            };
            cusApi.customerList(params).then((res) => {
                if (res.data.code === 10200) {
                    this.total = res.data.data.total;
                    this.handleEditChangeAdd(res.data.data.items);
                }
                this.$emit('update:tableLoading', false);
            });
        },
        /**
         * 处理用户列表数据
         * @param {Array} data 用户列表数据
        */
        handleEditChangeAdd(data) {
            this.addCusData.forEach((ele) => {
                data.forEach((element) => {
                    if (element.id === ele.id) {
                        this.$set(element, 'isAdd', true);
                    }
                });
            });
            this.$emit('onChangeData', { customers: data });
        },
        /**
         * 点击查询按钮
        */
        handleSearchCus() {
            this.pageNumber = 1;
            this.$emit('update:tableLoading', true);
        },
        /**
         * 点击清空按钮
        */
        cancelCus() {
            this.customerForm.username = '';
            this.customerForm.email = '';
            this.$refs.customerForm.resetFields();
            // this.searchCus();
        },
        /**
         * 点击全部添加，或者添加单个用户
         * @param {string | Object} data all:全部添加
        */
        handleAdd(data) {
            const newCustomerData = [...this.customerData];
            const newAddCusData = [...this.addCusData];
            if (data === 'all') {
                if (this.customerData.length == 0) {
                    this.$$warning('先筛选要添加的用户吧~');
                    return;
                }
                newCustomerData.forEach((element) => {
                    if (this.addCusData.filter((e) => e.id === element.id).length == 0) {
                        newAddCusData.push(element);
                    }
                    this.$set(element, 'isAdd', true);
                });
            } else {
                if (this.addCusData.filter((e) => e.id === data.id).length > 0) {
                    this.$$warning('已经添加过啦~');
                    return;
                }
                newAddCusData.push(data);
                this.$set(data, 'isAdd', true);
            }
            this.$emit('onChangeData', { customers: newCustomerData, addCustomers: newAddCusData });
        },
        /**
         * 改变分页数
         * @param {number} val 分页数
        */
        handleSizeChange(val) {
            this.pageSize = val;
            this.$emit('update:tableLoading', true);
        },
        /**
         * 改变页码
         * @param {number} val 页码
        */
        handleCurrentChange(val) {
            this.pageNumber = val;
            this.$emit('update:tableLoading', true);
        }
    }
};
</script>
<style lang="less">
@import "./table.less";
</style>
