<template>
    <!-- 选择用户弹框 -->
    <el-dialog
        top="5%"
        :close-on-click-modal="false"
        :visible.sync="dialogCustomer"
        title="选择用户"
        class="aops-customers-dialog"
        :before-close="handleCancel"
        @open="handleOpen"
        @opened="tableLoading = true"
    >
        <div class="aop-customers-dialog-body">
            <customerTable
                :table-loading.sync="tableLoading"
                :customer-data="customerData"
                :add-cus-data="addCusData"
                @onChangeData="changeData"
            />
            <div class="aop-customers-right">
                <div class="aop-customers-title">已选择<span class="mg-l-12">{{ addCusData.length }}</span>
                </div>
                <div class="aop-explain">点击右下角确认按钮，将视为成功添加。</div>
                <el-table
                    ref="addTable"
                    header-cell-class-name="table-head aop-table-head"
                    cell-class-name="table-cell"
                    row-class-name="aop-table-row"
                    :data="addCusData"
                    row-key="id"
                    height="515px"
                >
                    <el-table-column
                        v-for="col in selectColumns"
                        :key="col.prop"
                        :prop="col.prop"
                        :label="col.label"
                        :width="col.width"
                        :min-width="col.minWidth"
                    >
                        <template v-if="col.prop === 'delete'" slot="header">
                            <el-button
                                type="text"
                                class="aop-table-header-oper"
                                @click="handleDel('all')"
                            >全部删除</el-button>
                        </template>
                        <template slot-scope="{ $index, row }">
                            <div v-if="col.prop === 'delete'" @click="handleDel($index)">
                                <i class="iconfont cursor-pointer">&#xe678;</i>
                            </div>
                            <div v-else-if="col.prop === 'username'">
                                {{ row.username }}
                            </div>
                            <div v-else>{{ row[col.prop] }}</div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="aop-customers-footer">
                    <el-button class="black-btn btn-s" @click="selectCusSubmit">确 认</el-button>
                    <el-button class="white-btn btn-s" @click="handleCancel">取 消</el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import customerTable from './table.vue';
import { selectColumns } from './data';

export default {
    components: { customerTable },
    props: {
        dialogCustomer: Boolean,
        addCusDataOrigin: {
            type: Array,
            default: () => []
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            tableLoading: false, // 左侧用户表格loading，控制是否重新调取接口获取左侧用户列表数据
            customerData: [], // 左侧用户数据集合
            selectColumns, // 已选用户表格的列
            addCusData: [] // 当前已选择的用户
        };
    },
    methods: {
        /**
         * 弹窗打开的回调
        */
        handleOpen() {
            this.addCusData = [...this.addCusDataOrigin];
        },
        /**
         * 关闭选择用户弹窗
        */
        handleCancel() {
            this.$emit('update:dialogCustomer', false);
            this.addCusData = [...this.addCusDataOrigin];
        },
        /**
         * 点击确定按钮
        */
        selectCusSubmit() {
            this.$emit('update:dialogCustomer', false);
            this.$emit('onChangeAddCusDataOrigin', [...this.addCusData]);
        },
        /**
         * 点击全部删除，或者删除单个用户
         * @param {string | number} data all:全部添加/单个用户index
        */
        handleDel(data) {
            if (data == 'all') {
                if (this.addCusData.length == 0) {
                    this.$$warning('暂无可删除的用户，请先筛选添加吧~');
                    return;
                }
                this.addCusData = [];
                this.tableLoading = true;
            } else {
                const arr = this.addCusData[data];
                this.addCusData.splice(data, 1);
                this.customerData.forEach((element) => {
                    if (element.id == arr.id) {
                        element.isAdd = false;
                        this.$set(element, 'isAdd', false);
                    }
                });
            }
        },
        /**
         * 获取用户列表数据，点击全部添加，添加单个用户，触发此方法
         * 更改用户列表数据，已选用户列表数据
         * @param {Object} data {customers, addCustomers}
        */
        changeData({ customers, addCustomers }) {
            if (customers) {
                this.customerData = customers;
            }
            if (addCustomers) {
                this.addCusData = addCustomers;
            }
        }
    }
};
</script>
<style lang="less">
@import "./customers.less";
</style>
