<template>
    <div class="aops-notice-edit-content">
        <el-form-item
            label="推送标题"
            :prop="prop.titleProp"
        >
            <el-input
                v-model="form[prop.titleProp]"
                :placeholder="titlePlaceHolder"
                style="width: 500px;"
            />
        </el-form-item>
        <el-form-item v-if="form.type === 1" label="简介" :prop="prop.descProp">
            <el-input
                v-model="form[prop.descProp]"
                type="textarea"
                :placeholder="descPlaceholder"
                :maxlength="textareaMaxLen"
                show-word-limit
                style="width: 672px;"
            />
        </el-form-item>
        <div>
            <el-button class="aop-insert-btn" @click="handleInsert">
                <i class="iconfont aop-insert-icon">&#xe675;</i> 引用变量
            </el-button>
        </div>
        <el-form-item
            class="aop-textEditor"
            :prop="prop.contentProp"
        >
            <template v-if="form.type === 1">
                <el-upload
                    ref="uploader"
                    class="aop-avatar-uploader"
                    accept=".jpg, .jpeg, .png, .apng, .gif, .bmp"
                    name="img"
                    action="#"
                    :auto-upload="false"
                    :on-change="handleUpImg"
                    :show-file-list="false"
                />
                <p class="aop-textImg-tip">注意！添加图片需从电脑端本机上传，其他网站上获取的图片存在过期风险！</p>
                <quill-editor
                    ref="textQuillEditor"
                    v-model="form[prop.contentProp]"
                    class="aop-textImgEdit"
                    :options="editImgOption"
                    @input="handleValidate"
                />
            </template>
            <template v-else>
                <quill-editor
                    ref="textQuillEditor"
                    v-model="form[prop.contentProp]"
                    class="aop-textEdit"
                    :options="editOption"
                    @change="editChange($event)"
                />
                <span class="aop-sizeTip">{{ contentLength }}/{{maxSize}}</span>
            </template>
        </el-form-item>
        <reference-variable
            ref="notice"
            @insert="handleInsertValue"
        ></reference-variable>
    </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
// import api from '@/api/message';
import uploadImg from '@/mixins/upload-img';
import referenceVariable from '@/components/reference-variable/index.vue';
import { textImgOptions,
    textOptions,
    textareaMaxLen,
    maxSize,
    placeholder,
    descPlaceholder,
    titlePlaceHolder, contPlaceholder } from './quill-config';

export default {
    components: { quillEditor, referenceVariable },
    mixins: [uploadImg],
    props: {
        form: {
            type: Object,
            default: () => ({
                type: 2
            })
        },
        lang: {
            type: String,
            default: 'cn'
        },
        prop: {
            type: Object,
            /**
             * 字段
             * @returns {*}
            */
            default() {
                return {
                    titleProp: 'title',
                    contentProp: 'content',
                    descProp: 'description'
                };
            }
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            editImgOption: {
                placeholder: contPlaceholder[this.lang],
                modules: {
                    toolbar: {
                        container: textImgOptions,
                        handlers: {
                            image: this.handleImage
                        }
                    }
                }
            },
            editOption: {
                placeholder: placeholder[this.lang],
                modules: {
                    toolbar: {
                        container: textOptions
                    }
                }
            },
            maxSize: maxSize[this.lang], // 文本编辑框可输入最大长度
            contentLength: 0, // 文本编辑框已输入的字符长度
            textareaMaxLen: textareaMaxLen[this.lang], // 图文简介可输入最大长度
            descPlaceholder: descPlaceholder[this.lang], // 简介提示语
            titlePlaceHolder: titlePlaceHolder[this.lang]
        };
    },
    /**
     * 实例渲染
    */
    mounted() {
        const quill = this.$refs.textQuillEditor.quill;
        if (this.form.type === 1 && quill) {
            // 实例重新渲染,仅影响实例当前组件
            this.$forceUpdate();
            // 自定义粘贴图片功能
            quill.root.addEventListener(
                'paste',
                (evt) => {
                    if (evt.clipboardData && evt.clipboardData.files && evt.clipboardData.files.length) {
                        evt.preventDefault();
                        [].forEach.call(evt.clipboardData.files, (file) => {
                            if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
                                return;
                            }
                            this.uploadToServer(file);
                        });
                    }
                },
                false
            );
        }
    },
    methods: {
        /**
         * 打开引用变量弹窗
        */
        handleInsert() {
            this.$nextTick(() => {
                this.$refs.notice.show();
            });
        },
        /**
         * 点击引用变量弹窗中的确定按钮
         * @param {string} data 选择的引用变量
        */
        handleInsertValue(data) {
            const quill = this.$refs.textQuillEditor.quill;
            if (quill) {
                const length = this.$refs.textQuillEditor.quill.selection.savedRange.index;
                quill.insertEmbed(length, data);
                quill.setSelection(length + data.length);
            }
        },
        /**
         * 文本编辑器change
         * @param {*} event event
        */
        editChange(event) {
            event.quill.deleteText(this.maxSize, 4);
            this.handleValidate();
            if (this.form[this.prop.contentProp] === '') {
                this.contentLength = 0;
            } else {
                this.contentLength = event.quill.getLength() - 1;
            }
        },
        /**
         * 编辑器校验
        */
        handleValidate() {
            this.$emit('onPropValid', this.prop.contentProp);
        },
        /**
         * 图片处理
         * @param {Boolean} value 值
        */
        handleImage(value) {
            if (value) {
                this.$refs.uploader.$refs['upload-inner'].$refs.input.click();
            } else {
                this.quill.format('image', false);
            }
        },

        /**
         * 上传图片
         * @param {File} file 图片
        */
        handleUpImg(file) {
            if (file.size > 1024 * 1024 * 5) {
                this.$$error('图片过大， 不能超过5M.');
                return;
            }
            // const formData = new FormData();
            // formData.append('image', file.raw);
            // api.upLoad(formData).then((res) => {
            //     const quill = this.$refs.textQuillEditor.quill;
            //     if (res.data.data) {
            //         const length = this.$refs.textQuillEditor.quill.selection.savedRange.index;
            //         quill.insertEmbed(length, 'image', res.data.data.url);
            //         quill.setSelection(length + 1);
            //     } else {
            //         this.$Message.error('Image upload error');
            //     }
            // });
            this.handleUploadImg(file).then((res) => {
                const quill = this.$refs.textQuillEditor.quill;
                const length = this.$refs.textQuillEditor.quill.selection.savedRange.index;
                quill.insertEmbed(length, 'image', res.url);
                quill.setSelection(length + 1);
            });
        },
        /**
         * 修改粘贴图片为上传服务器
         * @param {File} file 图片
        */
        uploadToServer(file) {
            const fileObj = {
                raw: file
            };
            this.handleUpImg(fileObj);
        }
    }
};
</script>
<style lang="less">
@import "./content.less";
</style>
