<template>
    <div class="aops-notice-edit-type">
        <div class="aop-edit-form-item">
            <el-form-item label="推送方式" required>
                <el-select v-model="form.way" disabled class="aop-edit-select">
                    <el-option
                        v-for="item in wayArr"
                        :key="item.name"
                        :label="item.name"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <dateTime :id="id" ref="dateTimeRef" :form="form" @onChangeForm="changeForm" />
            </el-form-item>
        </div>
        <div>
            <el-form-item label="推送类型" prop="type">
                <el-select v-model="form.type" disabled class="aop-edit-select">
                    <el-option
                        v-for="item in typeArr"
                        :key="item.name"
                        :label="item.name"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                label="推送范围"
                :required="!form.send_all_customers ? true : false"
            >
                <div>
                    <el-form-item :class="check_send && 'error'">
                        <el-select
                            v-model="form.send_all_customers"
                            @change="handleChange"
                        >
                            <el-option
                                v-for="item in rangeArr"
                                :key="item.name"
                                :label="item.name"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                        <div v-if="check_send" class="el-form-item__error">
                        请选择用户
                        </div>
                    </el-form-item>
                    <el-button
                        v-if="!form.send_all_customers"
                        type="text"
                        @click="handleCus"
                    >
                        <span>{{ selectCusText }}</span>
                    </el-button>
                </div>
            </el-form-item>
              <el-form-item label="推送渠道" prop="channel" required>
                <el-select v-model="form.channel" class="aop-edit-select" @change="handleChange">
                    <el-option
                        v-for="item in channelsArr"
                        :key="item.name"
                        :label="item.name"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
        </div>
        <customers
            :dialog-customer.sync="dialogCustomer"
            :add-cus-data-origin="addCusDataOrigin"
            @onChangeAddCusDataOrigin="changeAddCusDataOrigin"
        />
    </div>
</template>

<script>
import dateTime from './dateTime.vue';
import customers from '../customers/customers.vue';

export default {
    components: { dateTime, customers },
    props: {
        id: {
            type: [String, Number],
            default: ''
        },
        form: {
            type: Object,
            default: () => ({})
        },
        addCusDataOrigin: {
            type: Array,
            default: () => ([])
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            // 推送类型option
            typeArr: [{ name: '图文消息', value: 1 }, { name: '文本消息', value: 2 }],
            // 推送方式option
            wayArr: [
                { name: '立即推送', value: 0 },
                { name: '定时推送', value: 1 }
            ],
            // 推送范围
            rangeArr: [
                { name: '所有用户', value: 1 },
                { name: '关注用户', value: 4 },
                { name: '国内用户', value: 2 },
                { name: '海外用户', value: 3 },
                { name: '选择用户', value: 0 }
            ],
            // 推荐渠道
            channelsArr: [
                { name: '仅通知', value: 1 },
                { name: '仅邮件', value: 2 },
                { name: '通知及邮件', value: 3 },
                { name: '重要通知', value: 4 },
                { name: '重要通知及邮件', value: 5 }
            ],
            // 仅通知、仅邮件、通知及邮件
            dialogCustomer: false,
            // check_send: false, // 推送范围验证报错
            selectCusText: '选择用户'
        };
    },
    computed: {
        /**
         * 推送范围验证报错
         * @returns {boolean}
        */
        check_send() {
            return !!(!this.form.send_all_customers && !this.addCusDataOrigin.length);
        }
    },
    watch: {
        /**
         * 监听已选择的用户
         * @param {Array} newVal 已选择的用户
        */
        addCusDataOrigin(newVal) {
            if (newVal) {
                this.selectCusText = `已选择${newVal.length}人`;
            }
        },
        /**
         * 监听推送范围,若选择的是选择用户，则打开选择用户弹窗
         * @param {number} newVal 当前选择的
         * @param {number | string} oldVal 上一个选择的
        */
        'form.send_all_customers': function(newVal, oldVal) {
            if (newVal === 0 && oldVal !== '初始化') {
                this.handleCus();
            }
        }
    },
    methods: {
        /**
         * 更新父组件addCusDataOrigin值
         * @param {Array} data 新的已选用户集合
        */
        changeAddCusDataOrigin(data) {
            this.$emit('update:addCusDataOrigin', data);
        },
        /**
         * 改变表单form值
         * @param {Object} obj {key: val}
        */
        changeForm(obj) {
            this.$emit('onChangeForm', obj);
        },
        /**
         * 推送范围更改
        */
        handleChange() {
            // this.check_send = !this.form.send_all_customers;
            if ((this.form.send_all_customers === 2 || this.form.send_all_customers === 3)
            && [2, 3, 5].indexOf(this.form.channel) > -1) {
                this.$$warning('无法区分用户所属地，邮件将发送至所有用户');
            }
        },
        /**
         * 打开选择用户弹窗
        */
        handleCus() {
            this.dialogCustomer = true;
        }
    }
};
</script>
<style lang="less">
@import "./editType.less";
</style>
