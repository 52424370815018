import api from '@/api/message';
import { langData } from './data';
import editTitle from './title/title.vue';
import editType from './editType/editType.vue';
import editContent from './content/content.vue';
import sendDialog from './sendDialog.vue';
import viewDialog from '@/pages/_common/viewDialog/viewDialog.vue';

const requiredRule = { required: true, message: '需同时包含中英文推送信息，以确保用户浏览完整性', trigger: 'blur' };
const mixin = {
    components: { editTitle, editType, editContent, sendDialog, viewDialog },
    /**
     * data
     * @returns {object}
    */
    data() {
        return {
            id: '', // 消息id
            isLoading: false, // 编辑状态，消息信息获取loading
            rules: {
                channel: { required: true, message: '请选择推送渠道', trigger: 'blur' },
                title: [
                    requiredRule,
                    {
                        min: 1,
                        max: 50,
                        message: '长度在 1 到 50 个字符',
                        trigger: 'blur'
                    }
                ],
                title_en: [
                    requiredRule,
                    {
                        min: 1,
                        max: 100,
                        message: '长度在 1 到 100 个字符',
                        trigger: 'blur'
                    }
                ],
                content: [requiredRule],
                content_en: [requiredRule],
                scheduled_at: [
                    { required: true, message: '请选择推送日期', trigger: 'change' }
                ]
            },
            addCusDataOrigin: [], // 已确定的已选用户
            status: '',
            langData,
            dialogView: false, // 预览弹窗
            dialogSend: false, // 保存并推送确认弹窗
            confirmLoading: false // 确认弹窗 确认按钮loading
        };
    },
    computed: {
        /**
         * 给预览弹窗要传的参数
         * @returns {Object}
        */
        viewData() {
            return { ...this.form, time: `${this.form.scheduled_at} ${this.form.scheduled_time}` };
        }
    },
    /**
     * 实例创建完成
    */
    created() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id;
            this.form.send_all_customers = '初始化';
            this.getEditInfo();
        }
    },
    methods: {
        /**
         * 获取消息信息
        */
        getEditInfo() {
            this.isLoading = true;
            api.getMessageInfo(this.id).then((res) => {
                this.isLoading = false;
                if (res.data.code === 10200) {
                    this.handleForm(res.data.data);
                } else {
                    this.$$error(res.data.message);
                }
            });
        },
        /**
         * 处理消息信息
         * @param {Object} data 消息信息
        */
        handleForm(data) {
            this.form = {
                title: data.title,
                content: data.content,
                title_en: data.title_en || data.title,
                content_en: data.content_en || data.content,
                description: data.description,
                description_en: data.description_en,
                type: data.type,
                way: !data.scheduled_at ? 0 : 1,
                send_all_customers: data.send_all_customers,
                scheduled_at: data.scheduled_at,
                channel: data.channel
            };
            this.status = data.status;
            this.addCusDataOrigin = data.customer || [];
            if (this.addCusDataOrigin.length !== 0) {
                this.selectCusText = `已选择${this.addCusDataOrigin.length}人`;
            }
            const arr = (this.form.scheduled_at && this.form.scheduled_at.length !== 0)
                ? this.form.scheduled_at.split(' ')
                : [];
            if (arr.length !== 0) {
                this.form.scheduled_at = arr[0];
                this.$set(this.form, 'scheduled_time', arr[1]);
            }
            this.$refs.editTypeRef.$refs.dateTimeRef.setTimeOptions(data.scheduled_at);
        },
        /**
         * 返回消息列表页，刷新消息列表
        */
        back() {
            this.$router.push('/operations/notice');
            this.msgBus.$emit('noticeList');
        },
        /**
         * 改变表单form值
         * @param {Object} obj {key: val}
        */
        changeForm(obj) {
            this.form = {
                ...this.form,
                ...obj
            };
        },
        /**
         * 获取接口请求所需参数
         * @returns {Object}
        */
        getApiParams() {
            const customerIds = this.addCusDataOrigin.map((item) => item.id);
            const newData = { ...this.form };
            delete newData.way;
            delete newData.scheduled_time;
            const data = {
                ...newData,
                id: this.id || null,
                customer_ids: customerIds,
                scheduled_at: this.form.way === 1
                    ? `${this.form.scheduled_at} ${this.form.scheduled_time}`
                    : ''
            };
            return data;
        },
        /**
         * 保存且推送
        */
        sendSubmit() {
            this.confirmLoading = true;
            const data = this.getApiParams();
            if (this.id) {
                api.messageUpOrSend(data).then((res) => {
                    this.handleCallBack(res.data);
                });
            } else {
                api.messageAddOrSend(data).then((res) => {
                    this.handleCallBack(res.data);
                });
            }
        },
        /**
         * 点击保存按钮
        */
        confirm() {
            const valid = this.handleValidate();
            if (valid) {
                const data = this.getApiParams();
                // this.confirmLoading = true;
                if (this.$route.query.id || this.id) {
                    api.messageUpdate(data).then((res) => {
                        this.handleCallBack(res.data);
                    });
                } else {
                    api.create(data).then((res) => {
                        this.handleCallBack(res.data);
                    });
                }
            }
        },
        /**
         * 点击保存并推送，打开确认推送弹窗
        */
        handleSend() {
            const valid = this.handleValidate();
            if (valid) {
                this.dialogSend = true;
            }
        },
        /**
         * 点击请求按钮之前的, 表单验证
         * @returns {*}
        */
        handleValidate() {
            let result = true;
            const customerIds = this.addCusDataOrigin.map((item) => item.id);
            this.$refs.form.validate((valid) => {
                if (valid
                && ((this.form.send_all_customers === 0 && customerIds.length !== 0)
                || this.form.send_all_customers > 0)
                ) {
                    if (this.status === 0) {
                        if (this.$refs.editTypeRef.$refs.dateTimeRef.handleTimeChange()) { result = false; }
                    }
                } else {
                    result = false;
                }
            });
            return result;
        },
        /**
         * 处理接口返回结果
         * @param {*} data 接口返回结果
        */
        handleCallBack(data) {
            if (data.code === 10200) {
                this.$refs.form.resetFields();
                this.$$success('操作成功');
                this.back();
            } else {
                this.$$error(data.message);
            }
            this.confirmLoading = false;
        },
        /**
         * 单个字段校验
         * @param {string} prop 字段
        */
        handlePropValid(prop) {
            this.$refs.form.validateField([prop]);
        },
        /**
         * 打开预览
        */
        handlePreview() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.dialogView = true;
                }
            });
        }
    }
};

export default mixin;
