export const tableColumns = [
    {
        prop: 'username',
        label: '用户名称',
        width: 222,
        minWidth: '20%'
    },
    {
        prop: 'email',
        label: '用户邮箱',
        width: 222,
        minWidth: '30%'
    },
    { prop: 'add', label: '全部添加', width: 190 }
];
export const selectColumns = [
    {
        prop: 'username',
        label: '用户名称',
        width: 180,
        minWidth: '20%'
    },
    {
        prop: 'email',
        label: '用户邮箱',
        width: 180,
        minWidth: '30%'
    },
    { prop: 'delete', label: '全部删除' }
];
