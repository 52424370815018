<template>
    <div class="aops-notice-edit-datetime">
        <el-form-item prop="scheduled_at">
            <el-date-picker
                v-if="form.way === 1"
                v-model="form.scheduled_at"
                type="date"
                value-format="yyyy-M-d"
                :picker-options="datePick"
                align="center"
                placeholder="请选择日期"
                clearable
            ></el-date-picker>
        </el-form-item>
        <el-form-item
            prop="scheduled_time"
            :rules="[
                {
                    required: form.scheduled_at ? true : false,
                    message: '请选择时间',
                    trigger: 'change',
                },
            ]"
        >
            <el-time-picker
                v-if="form.way === 1"
                v-model="form.scheduled_time"
                :disabled="!form.scheduled_at ? true : false"
                value-format="H:m:s"
                :picker-options="{ selectableRange: selectableRange }"
                placeholder="请选择时间"
                @change="handleTimeChange"
            ></el-time-picker>
        </el-form-item>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: [String, Number],
            default: ''
        },
        form: {
            type: Object,
            default: () => ({
                way: 1
            })
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        /**
         * 禁用日期(小于今天)
         * @param {Date} time 日期
         * @returns {Boolean}
        */
        const disableEndDate = (time) => {
            const _now = Date.now() - 3600 * 1000 * 24;
            return time.getTime() < _now;
        };
        return {
            datePick: {
                /**
                 * 设置禁用状态
                 * @param {Date} time 日期
                 * @returns {Boolean}
                */
                disabledDate(time) {
                    return disableEndDate(time);
                }
            },
            selectableRange: '00:00:00 - 23:59:59' // 可选时间段
        };
    },
    watch: {
        /**
         * 监听推送日期
         * @param {string} newVal 当前选择的日期
         * @param {string} oldVal 上一个选择的日期
        */
        'form.scheduled_at': function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.setTimeOptions(newVal);
                const data = new Date(newVal).getTime();
                const now = new Date();
                const nowTime = this.$moment(now).format('YYYY-MM-DD');
                const nowTimestamp = new Date(nowTime).getTime();
                if (data > nowTimestamp) {
                    this.selectableRange = '00:00:00 - 23:59:59';
                }
                this.getPassTen();
            }
        }
    },
    methods: {
        /**
         * 改变表单form值
         * @param {Object} obj {key: val}
        */
        changeForm(obj) {
            this.$emit('onChangeForm', obj);
        },
        /**
         * 日期更改的时候，设置时间
         * （当前选择的日期毫秒数 > 当前日期+10min） ？ 显示00:00:00 ：显示当前时间+10min
        */
        getPassTen() {
            const date = new Date();
            const min = date.getMinutes();
            // 当前时间加10分钟
            date.setMinutes(min + 10);
            const y = date.getFullYear();
            const m = date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1;
            const d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const h = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
            const f = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
            const s = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
            const now = new Date(`${y}-${m}-${d}`).getTime();
            const selectDate = new Date(this.form.scheduled_at).getTime();
            let scheduledTime = '';
            if (selectDate > now) {
                scheduledTime = '00:00:00';
            } else {
                scheduledTime = `${h}:${f}:${s}`;
            }
            setTimeout(() => {
                this.changeForm({ scheduled_time: scheduledTime });
            // this.$set(this.form, 'scheduled_time', scheduledTime);
            });
        },
        /**
         * 设置时间选择范围
         * @param {string} newVal 当前选择的日期
        */
        setTimeOptions(newVal) {
            const selectTimestamp = this.id
                ? new Date(this.form.scheduled_at).getTime()
                : new Date(newVal).getTime();
            const now = new Date();
            const nowTime = this.$moment(now).format('YYYY-MM-DD');
            const nowTimestamp = new Date(nowTime).getTime();
            let selectRange = '';
            if (selectTimestamp > nowTimestamp) {
                this.selectableRange = '00:00:00 - 23:59:59';
            } else {
                const hh = parseInt(now.getHours(), 10);
                const mm = parseInt(now.getMinutes(), 10);
                if (hh < 23) {
                    if (mm < 50) {
                        const t = mm + 10;
                        selectRange = `${hh}:${t}:00`;
                    } else {
                        const h = hh + 1;
                        selectRange = `${h}:00:00`;
                    }
                } else if (mm < 50) {
                    const t = mm + 10;
                    selectRange = `${hh}:${t}:00`;
                } else {
                    const h = mm + 1;
                    selectRange = `${hh}:${h}:00`;
                }
                this.selectableRange = `${selectRange} - 23:59:59`;
            }
        },
        /**
         * 时间更改
         * @returns {*}
        */
        handleTimeChange() {
            const date = new Date();
            const y = `${date.getFullYear()}-`,
                M = `${date.getMonth() + 1}-`,
                d = date.getDate(),
                h = `${date.getHours()}:`,
                m = `${date.getMinutes()}:`;
            let s = date.getSeconds();
            if (s < 10) {
                s = `0${s}`;
            }
            const nowTime = `${y}${M}${d} ${h}${m}${s}`;
            const now = new Date(nowTime).getTime();
            const selectTime = this.form.scheduled_at.includes(' ')
                ? this.form.scheduled_at
                : `${this.form.scheduled_at} ${this.form.scheduled_time}`;
            const select = new Date(selectTime).getTime();
            const nowDate = new Date(y + M + d).getTime();
            const selectDate = new Date(this.form.scheduled_at).getTime();
            if (now > select) {
                const formParams = {};
                formParams.scheduled_time = '00:00:00';
                if (nowDate > selectDate) { formParams.scheduled_at = ''; }
                this.changeForm(formParams);
                this.$$error('推送时间不能小于当前时间，请修改推送时间');
                return true;
            }
        }
    }
};
</script>
<style scoped>
    .aops-notice-edit-datetime {
        display: inline-block;
    }
</style>
